export function getPublicApiTokenDetailPageRoutes(currentModule: string, routePrefix: string) {
  return [
    {
      path: routePrefix,
      name: `${currentModule}/public-api-token-outlet`,
      component: () => import("./components/PublicApiTokenDetailWrapper.vue"),
      meta: {
        isDetailPageOutlet: true,
      },
      children: [
        {
          path: `:user/general`,
          name: `${currentModule}/public-api-token-general`,
          component: () => import("./views/PublicApiTokenEditGeneral.vue"),
          meta: {
            isDetailPage: true,
            module: "settings",
          },
        },
      ],
    },
  ]
}

export function getSettingsRoutes() {
  return [
    {
      path: "/settings",
      name: "settings",
      component: () => import(/* webpackChunkName: settings-old-backend */ "./components/SettingOld.vue"),
    },
    {
      path: "/settings/old/cron-jobs",
      name: "settings/old/cron-jobs",
      component: () => import("./components/SettingCronJobsOld.vue"),
    },
    {
      path: "/settings/public-api-tokens",
      name: "settings/public-api-tokens",
      component: () => import("./views/PublicApiTokenList.vue"),
      children: getPublicApiTokenDetailPageRoutes("settings", "detail"),
    },
    {
      path: "/settings/cron-jobs",
      name: "settings/cron-jobs",
      component: () => import("./views/CronJobsList.vue"),
    },
  ]
}
